<template>
  <div class="pt-56">
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row class="px-1">
          <!-- 開戶姓名 -->
          <v-col
            cols="6"
            class="pt-4 pb-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankAccountName') }}
            </span>
            <v-text-field
              v-model="userName"
              class="mt-2"
              min-height="36"
              disabled
              dense
              outlined
              required
            />
          </v-col>

          <!-- 開戶銀行 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankName') }}
            </span>
            <v-text-field
              v-model="dispalyInput.bankName"
              class="mt-2"
              max-height="36"
              readonly
              dense
              outlined
              required
              :rules="[rules.required.bankCode]"
              :placeholder="$t('form.missBank')"
              @click="dialog.status = true"
            >
              <div
                v-if="activeBank"
                slot="prepend-inner"
                class="bank__inner d-flex align-center p-relative"
              />
              <div slot="append">
                <v-icon size="23">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-text-field>
          </v-col>

          <!-- 銀行卡號 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankCardNo') }}
            </span>
            <v-text-field
              v-model.trim="form.bank_no"
              class="mt-2"
              max-height="36"
              dense
              :placeholder="$t('form.missCardNo')"
              single-line
              outlined
              inputmode="numeric"
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[
                rules.required.bankCard,
                rules.bankCard.minLength,
                rules.bankCard.maxLength,
              ]"
              @keyup="bankIdFormat"
            >
              <div slot="clear-icon">
                <v-icon>mdi-close-circle</v-icon>
              </div>
            </v-text-field>
          </v-col>

          <!-- 开户省市 (僅有 cn 需要選擇 「開戶省分」) -->
          <v-col
            v-if="nation === 'china' ? true : false"
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.provinceCity') }}
            </span>
            <v-text-field
              class="mt-2"
              max-height="36"
              dense
              outlined
              required
              readonly
              :placeholder="$t('form.missProvince')"
              :value="bankCitiesInput"
              :rules="[rules.required.provinceNcity]"
              @click="showSheet"
            >
              <div slot="append">
                <v-icon size="23">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-btn
              block
              height="44"
              class="subtitle-1"
              color="primary"
              :disabled="!valid"
              @click="submitForm"
            >
              {{ $t('global.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- 選擇銀行 Dailog -->
    <v-dialog
      v-model="dialog.status"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="bg">
        <div class="p-fixed w-100 bg">
          <v-toolbar
            class="bg"
          >
            <v-btn
              icon
              @click="closeSearchBankList"
            >
              <Icon
                data="@icon/toolbar/arrow_left.svg"
                width="16px"
                height="16px"
                class="icon--text"
              />
            </v-btn>
            <v-toolbar-title>
              {{ $t('form.missBank') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <!-- 搜尋銀行 -->
          <v-list
            three-line
            subheader
            class="bg"
          >
            <div class="search-area">
              <v-text-field
                id="searchInput"
                v-model.trim="dialog.searchKeyword"
                autofocus
                class="mx-4 mt-2 inputBg"
                hide-details
                :placeholder="$t('game.searchGame')"
                rounded
                autocomplete="off"
                dense
              >
                <!-- 放大鏡 -->
                <div
                  slot="prepend-inner"
                  class="mt-0"
                >
                  <v-btn
                    slot="btn"
                    icon
                  >
                    <Icon
                      data="@icon/search.svg"
                      width="24"
                      height="24"
                      class="white--text"
                    />
                  </v-btn>
                </div>
              </v-text-field>
            </div>
          </v-list>
        </div>

        <!-- 銀行列表 -->
        <v-list
          class="bg pt-110px"
          flat
        >
          <v-list-item-group
            color="primary"
          >
            <v-list-item
              v-for="bankItem in filterBankList"
              :key="bankItem._id"
              @click="setBankId(bankItem.code)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="bankItem.name" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- 開戶省市 Sheet -->
    <v-bottom-sheet v-model="sheet.status">
      <v-sheet class="text-center">
        <v-list height="318px">
          <!-- province -->
          <template v-if="!form.bank_province">
            <v-subheader class="px-6">
              <span class="text-h6 title--text">
                {{ $t('myCenter.selectProvince') }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(item, province) in sheet.cities"
                :key="province"
                class="text-left px-6"
                @click="setProvince(province)"
              >
                <v-list-item-title>{{ province }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>

          <!-- cities -->
          <template v-else>
            <v-subheader class="px-6">
              <v-icon
                class="mr-6"
                color="primary"
                @click="resetProvince"
              >
                mdi-chevron-left
              </v-icon>
              <span class="text-h6 title--text">
                {{ form.bank_province }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(city, province) in sheet.cities[form.bank_province]"
                :key="province"
                class="text-left px-6"
                @click="setCities(city)"
              >
                <v-list-item-title>{{ city }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'

export default {
  components: { },

  data: () => ({
    valid: true,

    bankCode: '',

    form: {
      bank_id: '', // 銀行代碼
      bank_no: '', // 銀行卡號，必須是唯一值，無法建立資料庫已有的卡號
      defray_name: '', // 開戶姓名
      phone: '', // 手機號碼
      bank_city: '', // 開戶市
      bank_province: '', // 開戶省
    },

    dispalyInput: {
      bankName: '',
    },

    dialog: {
      status: false,
      bankList: [],
      searchKeyword: '',
    },

    sheet: {
      status: false,
      cities: [],
    },
  }),

  computed: {
    ...mapState({
      userName: state => state.user.userData.member.username,
      formatNation: state => state.websiteData.phone_format_nation,
    }),
    ...mapGetters(['baseImgUrl', 'lang', 'nation']),

    activeBank() {
      return this.dialog.bankList.find(item => item.code === this.form.bank_id)
    },

    /**
     * 搜尋銀行 (篩選 關鍵字 搜尋的銀行)
     * @date 2021-10-20
     * @returns {array} 符合搜尋的銀行項目
     */
    filterBankList() {
      if (!this.dialog.searchKeyword) return this.dialog.bankList
      return this.dialog.bankList.filter(this.searchBankList)
    },

    // 表單驗證
    rules() {
      return {
        required: {
          bankCard: value => !!value || this.$t('form.missCardNo'),
          bankCode: value => !!value || this.$t('form.missBank'),
          provinceNcity: value => !!value || this.$t('form.missProvince'),
        },
        bankCard: {
          minLength: bank_no => {
            if (!bank_no) return false
            if (this.formatNation === 'thai') {
              // 不能 大於 10個字元 + 2個2字元空格 (泰國)
              return bank_no.length >= 14 || this.$t('form.errorCardNo')
            }

            // 不能 少於 16個字元 + 3個2字元空格 (中國)
            return bank_no.length >= 22 || this.$t('form.errorCardNo')
          },

          // 不能 大於 19字元 + 4個2字元空格
          maxLength: bank_no => {
            if (!bank_no) return false

            if (this.formatNation === 'thai') {
              // 不能 少於 10個字元 + 2個2字元空格 (泰國)
              return bank_no.length <= 14 || this.$t('form.errorCardNo')
            }

            return bank_no.length <= 27 || this.$t('form.errorCardNo')
          },
        },
      }
    },

    bankCitiesInput() {
      if (this.form.bank_province && this.form.bank_city) { return this.form.bank_province + '  ' + this.form.bank_city }
      return undefined
    },
  },

  watch: {
    // 重置搜尋關鍵字
    'dialog.status'(newStatus) {
      if (!newStatus) this.dialog.searchKeyword = ''
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('flashMessage.addCard'))
    this.getBankList()
    this.getUserBaseInfo()
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_bank_list',
      'get_nation_city',
      'create_user_bank_card',
      'get_user_base_info',
      'show_alert',
    ]),

    setBanckList(bankList) {
      this.dialog.bankList = bankList.banks
    },

    getUserBaseInfoSuccess({ member }) {
      this.form.phone = member.contact_no
      this.form.defray_name = member.username
    },

    async getUserBaseInfo() {
      const userBaseInfo = await this.get_user_base_info()
      resStatus(userBaseInfo, this.getUserBaseInfoSuccess)
    },

    async getBankList() {
      const bankList = await this.get_bank_list()
      resStatus(bankList, this.setBanckList)
    },

    setBankId(bankId) {
      this.form.bank_id = bankId
      this.dispalyInput.bankName = this.activeBank.name
      this.dialog.status = false
    },

    /**
     * 信用卡號格式化
     * @date 2021-03-29
     */
    bankIdFormat() {
      this.form.bank_no = this.form.bank_no
        .replace(/\D/g, '')
        .replace(/\s/g, '')
        .replace(/(\d{4})(?=\d)/g, '$1  ')
    },

    /**
     * 信用卡移除格式化
     * @date 2021-03-29
     * @param {string} bankId
     * @returns {string} 移除空格的信用卡號
     */
    bankIdRepalce(bankId) {
      return bankId.split('  ').join('')
    },

    /**
     * 設置語系方法
     * @date 2021-04-06
     * @param {string}} naion 國家名稱
     * @returns {object}} 國家省城資料
     */
    async getCityData(nation) {
      return await this.get_nation_city(nation)
    },

    listScrollToTop() {
      document.querySelector('.list').scrollTo(0, 0)
    },

    setProvince(province) {
      this.form.bank_province = province
      this.listScrollToTop()
    },

    resetProvince() {
      this.form.bank_province = ''
      this.listScrollToTop()
    },

    setCities(city) {
      this.form.bank_city = city
      this.sheet.status = false
    },

    /**
     * 開戶省份 (彈窗開啟)
     * @date 2021-07-06
     * @param {any} e
     * @param {string}} nation=this.nation 國家名稱
     */
    async showSheet(e, nation = this.nation) {
      this.sheet.status = true
      const citiesData = await this.getCityData(nation)
      this.sheet.cities = citiesData
    },

    /**
     * 開戶省份 (彈窗關閉)
     * @date 2021-07-06
     * @returns {any}
     */
    async closeSheet() {
      this.sheet.status = false
    },

    resetForm() {
      this.$refs.form.resetValidation()
      this.dispalyInput.bankName = ''
      Object.assign(this.form, this.$options.data().form)
      this.getUserBaseInfo()
    },

    submitSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('global.save') + this.$t('global.success'),
      })
      setTimeout(() => this.$router.replace({ name: 'card' }), 1300)
    },

    submitFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.save') + this.$t('global.fail'),
      })
    },

    /**
     * 發送表單 (新增卡片)
     * @date 2021-07-06
     */
    async submitForm() {
      this.$refs.form.validate()
      this.form.bank_no = this.bankIdRepalce(this.form.bank_no)
      this.$refs.form.resetValidation()
      const res = await this.create_user_bank_card(this.form)
      this.resetForm()
      resStatus(res, this.submitSuccess, this.submitFail)
    },

    /**
     * 更新彈窗組件狀態 (監聽元件更新事件)
     * @date 2021-09-23
     * @param {any} newStatus
     */
    updaDialog(newStatus) {
      this.dialog.status = newStatus
    },

    /**
     * 搜尋銀行功能
     * @date 2021-10-20
     * @param {object} bankItem 每個銀行項目
     * @returns {boolean} 是否符合搜尋條件
     */
    searchBankList(bankItem) {
      const bankCode = bankItem.code
      const bankName = bankItem.name
      if (bankCode.includes(this.dialog.searchKeyword)) return true
      if (bankName.includes(this.dialog.searchKeyword)) return true
    },

    closeSearchBankList() {
      this.dialog.status = false
    },
  },
}
</script>

<style lang="scss" scoped>
.bank {
	&__icon {
		display: inline-block;
		width: 18px;
		height: 18px;
	}
}

.h-318 {
	height: 318px;
}

.p-fixed {
	top: 0;
	z-index: 2;
}

.pt-110px {
	padding-top: 110px;
}
</style>
